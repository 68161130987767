import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <h2>Privacy Policy</h2>
    <p>This website is owned and run by Leem Trading Establishment ("we", "our", "us”), part of Al-Hala Trading Establishment. If we use the
      term “our Group”, this means us and our subsidiaries, our ultimate holding company and its subsidiaries (or any of them). The term
      “our online services” refers to Leem, our mobile and tablet applications, our pages on third party social media platforms such as
      Instagram, Facebook, Twitter, Pinterest and Google+ and any other websites or apps we own or run from time to time. The term “our
      services” refers to our online services and any of our other products and services offered from time to time. If you use any of our
      services, we will refer to you using the terms “user”, “visitor”, “you”, “your”, “yours” in this policy.</p>
    <p>We respect the privacy rights of our visitors and recognize the importance of protecting the information collected about them. This
      privacy policy is about how we collect, store, and use the personal information that you provide to us. As part of our commitment to
      your privacy rights and security</p>
    <p>This privacy policy explains:</p>
    <ul>
      <li><a href="#personal">What personal information do we collect from you and how?</a></li>
      <li><a href="#how">How this information may be used</a></li>
      <li><a href="#who">Who we will share your personal information with?</a></li>
      <li><a href="#marketing">Marketing opt-in and opt-out provisions</a></li>
      <li><a href="#access">How you can access your personal information and keep it up to date</a></li>
      <li><a href="#safeguarding">Safeguarding your personal information</a></li>
      <li><a href="#changes">How we can make changes to this policy</a></li>
      <li><a href="#questions">Where and how to ask questions or contact us about this policy</a></li>
      <li><a href="#otp">Regional Restrictions for Login and OTP Requests</a></li>
    </ul>
    <p>If you are under 16, you must inform a parent or guardian about our privacy policy to obtain their agreement to the privacy policy
      before registering, subscribing or placing an order with us.</p>
    <a name="personal" />
    <h2>WHAT PERSONAL INFORMATION DO WE COLLECT FROM YOU AND HOW?</h2>
    <p>We and our business partners collect information about your use of our online services using cookies. Cookies are very small files
      that are sent by us to your computer or other device which we can access when you visit our site in future. Cookies help us remember
      who you are and other information about your visits. They can help display the information on a website in a way that matches your
      interests. Most major websites use cookies.</p>
    <p>For more information about how we use cookies, please read our <a href="#cookies">cookie policy</a>.</p>
    <a name="how" />
    <h2>HOW THIS INFORMATION MAY BE USED</h2>
    <p>Whenever you give us your personal information we will use it in accordance with applicable privacy laws and for the purposes set out
      in this policy, on the data entry forms you complete, in any relevant terms and conditions and on pages or emails which link to the
      data entry forms.</p>
    <p>When you use our services: If you use any of our services, register for or attend one of our events, set up, look at or change your
      account details or a member of our Customer Care team, we will record your personal details. Your information will be used in the
      first place to provide the products, services or information you have asked for and to provide you with a personalized shopping
      experience. We keep the information you provide and may use it for several purposes, including: (i) accounting, billing, reporting and
      audit; (ii) credit checking or screening; (iii) authentication and identity checks; (iv) credit, debit or other payment card
      verification and screening; (v) debt collection; (vi) safety, security, health, training, administrative and legal purposes; (vii)
      data matching and dedupe, statistical and market analysis, and marketing information; (viii) advertising and marketing for us, our
      Group and third parties; (ix) developing, testing and maintaining systems; (x) studies, research and development; (xi) customer
      surveys; (xii) customer care and to help us in any future dealings with you, for example by identifying your requirements and
      preferences; (xiii) where required by law or in connection with legal proceeding or disputes; and (iv) any other uses set out in the
      terms and conditions for use of our services. For these purposes we may disclose your information to one or more of the other
      organizations listed in the section called “who we will share your personal information with”.</p>
    <p>Saved payment card details will only be shared with our payment partner and not with any other third parties and will only be used to
      process your order, using our payment partner's systems.</p>
    <p>We may also use your personal information to send you marketing updates, as detailed in the next section.</p>
    <p>Tracking how our online services are used: We collect, and use third parties to collect and share with us, information from lots of
      visitors to our online services and analyze it to build up a picture of how people use our websites. This helps us improve the
      services we offer. We may also give anonymous statistics about visitors to other, reputable organizations, but the information we
      provide will not include details that would allow these organizations to identify you. For more information on our use of cookies,
      please read our cookie policy.</p>
    <p>Customization of our online services and advert targeting: We may use the information you give us about yourself and the information
      we collect via cookies when you use our online services to build up a picture of your interests. We may then use this information to
      try to make sure that when we send you marketing communications and when you visit our websites or use our online services, you don't
      miss offers and information that might interest you. This information may also be used by our third-party advertising agencies and
      networks, to advertise our own- or third-party products and services to you when you visit third party websites and online services.
      This is called advert targeting or interest-based advertising. For more information on how to reject targeted advertising or
      interest-based advertising cookies please read our cookie policy.</p>
    <p>Testimonials: If you give us feedback, we may use it to improve our services and we may publish it online or offline to promote our
      business and our services. We will ask for your permission before we publish it.</p>
    <p>Comments and reviews submitted to our online services: If you wish to submit a comment or feedback on a blog or article featured on
      our services, we may (but are not obliged to) publish your comment online or offline to promote our business and our services. We will
      collect your name or username which will be displayed next to your comment and e-mail address, which will not be published, but which
      we may use to contact you in relation to your comment.</p>
    <p>Mobile services: When you request our mobile services, we may keep your mobile phone number, the make and model of your phone, the
      operating system used by your phone and details of your network operator, and we will link a unique identifier to your mobile phone
      number. We’ll store your device language, app language, country. We need this information in order to provide the features and
      services enabled through our mobile services and to administer our mobile service. Our Leem mobile services use your location to show
      your closest Leem store. We may also use it for SMS or voice marketing and market research.</p>
    <p>Social networks: If you follow us or interact with us on any of our pages on third party social media platforms, such as Instagram,
      Facebook, Twitter, Pinterest and Google+, information you provide will be subject to the third party’s privacy policy, as well as this
      privacy policy.</p>
    <p>Direct marketing: For information on how we may market to you, please read the section called “marketing opt-in and opt-out
      provisions”.</p>
    <p>Customer surveys: Every now and again, we may ask you for your opinion on our services and the products you have purchased through
      our services. When we do research or surveys, we may use cookies and may combine the information collected by those cookies with your
      answers.</p>
    <a name="who" />
    <h2>WHO WE WILL SHARE YOUR PERSONAL INFORMATION WITH</h2>
    <p>We will share your information with other companies in our Group, who may use it for their own business purposes, as set out in their
      privacy policy. These purposes may include financial reporting and analysis, strategic planning, the development of customer
      segmentation and metrics to provide a consistent view of our customer base, research and analytics, to help us source the right
      products in future and make better product recommendations, more effective targeting of our marketing campaigns, the creation of
      inspiring content and editorial features, the development of new products and collaboration opportunities with designer brands. It
      will also be used to provide more tailored marketing by phone, post, email, SMS or any other means (electronic or otherwise) and you
      expressly agree to such use.</p>
    <p>From time to time, we may engage third party business partners to provide information about you or to collect personal information on
      our behalf. We may also share or match your data with third party business partners to provide you with the products, services or
      information you ask for or for interest-based advertising. We may pass your information onto one or more of the following
      organizations: (i) data processing companies, mailing houses and other third party suppliers working on our Group’s behalf; (ii)
      ad-serving agencies and other advertising intermediaries; (iii) credit reference or fraud prevention agencies, which may keep a record
      of that information; (iv) research students, universities and other research and development organizations; (v) regulatory bodies,
      government and enforcement agencies, such as the police.</p>
    <p>Every now and again, we receive requests for information from government departments, the police and other enforcement agencies. If
      this happens, and there is a proper legal basis for providing your personal information, we will provide it to the organization asking
      for it.</p>
    <p>We collate information about site traffic, sales, wish lists, and other commercial information which we may pass onto third parties,
      but this information does not include any details which can identify you personally.</p>
    <a name="marketing" />
    <h2>MARKETING OPT-IN AND OPT-OUT PROVISION</h2>
    <p>We offer you the chance to receive news and fashion updates which, depending on your preferences, we will discuss with you by phone
      or live chat or send to you via email, SMS and/or direct mail. These include alerts for new products, features, enhancements, special
      offers, upgrade opportunities, contests, events of interest, and one-off marketing promotions. You can opt-out of receiving these
      updates, if you wish.</p>
    <p>We, or our third-party business partners, may also ask you if you want to receive marketing if you enter a promotion or attend an
      event thrown or sponsored by us. Other companies in our Group may also pass your personal information to us for marketing use.</p>
    <p>Marketing communications you subscribe to will only be sent by our Group.</p>
    <p>You have the right to ask us not to use your personal information for marketing. At all times, we will offer you the opportunity to
      unsubscribe from any service or update to which you have subscribed, if you change your mind. Whenever you receive direct marketing
      from us, we will tell you how to unsubscribe. Alternatively, you can change your marketing preferences by logging into My Account. To
      opt out of direct mail, please contact our Customer Service team on 800 440 0099 (KSA) or +966 800 440 0099 (internationally) or by email
      at customercare@leem.com</p>
    <p>If you tell us that you do not want to receive direct marketing, we will still contact you in order to provide you with products and
      services you request and for administration purposes.</p>
    <a name="access" />
    <h2>HOW YOU CAN ACCESS YOUR PERSONAL INFORMATION AND KEEP IT UP TO DATE</h2>
    <p>You have the right to see information we hold about you, with some exceptions which are described in the privacy laws. If you would
      like a copy of your personal information, please contact our privacy officer at privacy@leem.com. You will need to pay a processing
      fee.</p>
    <p>You have the right to review and update your personal details. If for any reason you are concerned that the personal information we
      hold is not correct, please visit our online services and, after logging into the site using the "Sign In" menu on the home page, your
      personal information will be made available for review and change in the "My Account" section. Only you or, upon your request, our
      Customer Care team, may access your personal data from our online services using your user ID and password. Information may be changed
      online within “My Details”, “Shipping Details” and “My Email Preferences”. You can change or delete saved credit/debit card details
      each time you make a purchase. You will also be able to delete saved credit/debit card details by adding or editing a shipping/billing
      address. If you change your billing or shipping address while your order is still being processed, the order will be re-processed
      through security validation checks. If you prefer, you may contact us by email at customercare@leem.com and we will amend your
      personal details.</p>
    <p>Our Live Chat provider stores all chat conversations for 13 months. To receive a copy of your chat conversation simply request this
      when exiting Live Chat or contact our Customer Care team by emailing customercare@leem.com for chats.</p>
    <a name="safeguarding" />
    <h2>SAFEGUARDING YOUR PERSONAL INFORMATION</h2>
    <p>We will take reasonable care to maintain appropriate safeguards to ensure the security, integrity and privacy of the information you
      have provided to us. We have put in place technology and security policies which are designed to protect the personal information we
      hold about you. We also follow the security procedures that applicable privacy laws require. These covers storing, using and releasing
      any information you have provided and, as well as measures designed to prevent unauthorized access or use. When you place an order or
      access your account information, we use a Secure Socket Layer (SSL) encryption which encrypts your information before it is sent to us
      to protect it from unauthorized use.</p>
    <a name="changes" />
    <h2>HOW WE CAN MAKE CHANGES TO THIS POLICY</h2>
    <p>We may update this privacy policy from time to time so you may want to check it each time you give us personal information or use our
      websites.</p>
    <a name="questions" />
    <h2>WHERE AND HOW TO ASK QUESTIONS OR CONTACT US ABOUT THIS POLICY</h2>
    <p>If you require more information, please contact our privacy officer at privacy@leem.com
      If you wish to talk to a Customer Service representative, please call +966 800 440 0099
    </p>
    <a name="otp" />
    <h2>Regional Restrictions for Login and OTP Requests</h2>
    <p>Our app is designed to allow user registration and login from specific regions only. Users can log in to our app using phone numbers
      registered in the following countries:
    </p>
    <ul>
      <li>Saudi Arabia (+966)</li>
      <li>United Arab Emirates (+971)</li>
      <li>Bahrain (+973)</li>
      <li>Oman (+968)</li>
      <li>Kuwait (+965)</li>
      <li>Qatar (+974)</li>
    </ul>
    <p>For security purposes, if a login attempt is made from a region outside these countries, the app will not generate an OTP (One-Time Password).
      This measure is in place to ensure the security and compliance of our app services.
    </p>
  </Layout>
);

export default IndexPage
